export default function DailyReportDataTable({ tableData }) {
  function getStatus(itemStatus) {
    switch (itemStatus) {
      case 1:
        return "Created";
      case 2:
        return "Processed";
      case 3:
        return "Pending";
      case 4:
        return "Approved";
      case 5:
        return "Rejected";
      case 6:
        return "Turkey Branch";
      case 7:
        return "Cancelled";
      case 9:
        return "Lost";
      case 10:
        return "To be Returned";
      case 11:
        return "Returned";
      case 12:
        return "Refunded";
      case 13:
        return "Damaged Item";
      case 14:
        return "Wrong Item";
      case 15:
        return "Lost in Office";
      case 16:
        return "Ignored";
      case 17:
        return "In Transit to Branch";
      case 18:
        return "Out for Delivery";
      case 19:
        return "Checked";
      case 20:
        return "Completed";
      case 21:
        return "Delivered to Branch";
      case 22:
        return "Lost Aboard";
      case 23:
        return "Lost in Transit";
      case 24:
        return "Purchased";
      case 25:
        return "Stock";
      case 26:
        return "Ordered";
      default:
        return "!!ERROR!!";
    }
  }

  return (
    <div className="daily-report-data-table">
      <div className="daily-report-data-table-header table-items">
        <p className="data-table-header-item">Status</p>
        <p className="data-table-header-item">Count</p>
        <p className="data-table-header-item">Total Price</p>
        <p className="data-table-header-item">Shipping price</p>
        <p className="data-table-header-item">Point</p>
        <p className="data-table-header-item">Currency</p>
      </div>
      <div className="data-table-items">
        {tableData.map((item) => {
          return (
            <div
              key={
                getStatus(item.statusID) + item.totalPrices + item.totalPoint
              }
              className="daily-report-data-table-item table-items"
            >
              <p>{getStatus(item.statusID)}</p>
              <p>{item.count}</p>
              <p>${item.totalPrices}</p>
              <p>${item.totalShippingPrices}</p>
              <p>${item.totalPoint}</p>
              <p>${item.totalCurrency}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

import { Outlet } from "react-router-dom";
import SideNavPanel from "../components/SideNavPanel";
import "./home.css";
import "../components/componentsStyle.css";

export default function Home({ isLogin }) {
  if (!isLogin) {
    return null;
  } else {
    return (
      <div className="home-container">
        <SideNavPanel />
        <Outlet />
      </div>
    );
  }
}

import "./App.css";
import { useState, useEffect } from "react";
import { Route, Routes, redirect, useNavigate } from "react-router-dom";
import { fetchUser } from "./services/fetchAPI";

import Login from "./pages/Login";
import Home from "./pages/Home";
import Bank from "./pages/Bank";
import IncomeStatment from "./pages/IncomeStatment";

function App() {
  let navigate = useNavigate();
  let [userData, setUserData] = useState();
  const isLogin = sessionStorage.getItem("isLogin");

  useEffect(() => {
    if (!isLogin) {
      navigate("/login");
    }
  }, [isLogin]);

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login setUserData={setUserData} />} />
      </Routes>
      <Routes>
        <Route path="/" element={<Home isLogin={isLogin} />}>
          <Route index element={<IncomeStatment />} />
          <Route index path="/income" element={<IncomeStatment />} />
          <Route path="/bank" element={<Bank />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;

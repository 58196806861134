import "./home.css";
import MoneyStorageDisplay from "../components/MoneyStorageDisplay";
import DailyReportDataTable from "../components/DailyReportDataTable";
import Loader from "../components/Loader";
import ArrowPointingDownSVG from "../images/ArrowPointingDown.svg";
import CancelButton from "../images/CancelButton.svg";
import DataBaseSVG from "../images/DataBase.svg";
import FilterDataSVG from "../images/FilterData.svg";
import { getIncomeData, getDailyReportData } from "../services/fetchAPI";
import { useEffect, useState } from "react";
import PopUp from "../components/popUp";
import convertToExcel from "../components/convertToExcel";

export default function IncomeStatment() {
  let [incomeData, setIncomeData] = useState(null);
  let [dailyData, setDailyData] = useState(null);
  let [filterDailyData, setFilterDailyData] = useState(false);
  let [filterData, setFilterData] = useState({
    type: "day",
    from: "",
    to: "",
  });

  const token = sessionStorage.getItem("token");

  function handleSubmitDailyFilter(e) {
    e.preventDefault();

    getDailyReportData(filterData, token).then((dailyItems) => {
      setDailyData(dailyItems);
    });
  }

  useEffect(() => {
    getIncomeData(token).then((items) => {
      setIncomeData(items);
    });
  }, []);
  useEffect(() => {
    getDailyReportData(filterData, token).then((dailyItems) => {
      setDailyData(dailyItems);
    });
  }, []);

  function VarifyCountExist(item) {
    return incomeData.system.find((e) => e.statusID == item) == undefined
      ? 0
      : incomeData.system.find((e) => e.statusID == item).price;
  }
  function sumAllObject(item) {
    let thing = 0;
    Object.values(item).forEach((e) => {
      thing = parseFloat(e) + thing;
    });
    return thing;
  }
  function sumAllArray(item, child, aditional = "0") {
    let thing = 0;
    item.forEach((e) => {
      thing = parseFloat(e[child]) + thing;
    });
    return thing + aditional;
  }

  return !incomeData ? (
    <Loader />
  ) : !dailyData ? (
    <Loader />
  ) : (
    <div className="info-container">
      <div className="view-info-container">
        <p className="view-info-container-name">Storage</p>
        <div className="view-info-container-items-container">
          <div className="view-info-container-items">
            <MoneyStorageDisplay
              name={"Primary Bank"}
              ammount={incomeData.storage.primary}
              unit={"$"}
            />
            <MoneyStorageDisplay
              name={"Duhok Storage"}
              ammount={incomeData.storage.duhok}
              unit={"$"}
            />
            <MoneyStorageDisplay
              name={"Turkey Stroage"}
              ammount={incomeData.storage.turkey}
              unit={"$"}
            />
            <MoneyStorageDisplay
              name={"Turkey Card"}
              ammount={incomeData.storage.cards}
              unit={"TL"}
            />
            <MoneyStorageDisplay
              name={"Taxi"}
              ammount={incomeData.storage.taxi}
              unit={"$"}
            />
          </div>
          <div className="view-info-container-item-last">
            <MoneyStorageDisplay
              name={"Total"}
              ammount={sumAllObject(incomeData.storage)}
              unit={"$"}
              total={true}
            />
          </div>
        </div>
      </div>
      <div className="view-info-container">
        <p className="view-info-container-name">System</p>
        <div className="view-info-container-items-container">
          <div className="view-info-container-items">
            <MoneyStorageDisplay
              name={"Purchased"}
              ammount={VarifyCountExist(24)}
              unit={"$"}
            />
            <MoneyStorageDisplay
              name={"Turkey Branch"}
              ammount={VarifyCountExist(6)}
              unit={"$"}
            />
            <MoneyStorageDisplay
              name={"In Transet"}
              ammount={VarifyCountExist(17)}
              unit={"$"}
            />
            <MoneyStorageDisplay
              name={"Duhok Branch"}
              ammount={VarifyCountExist(21)}
              unit={"$"}
            />
            <MoneyStorageDisplay
              name={"Stock"}
              ammount={VarifyCountExist(25)}
              unit={"$"}
            />
            <MoneyStorageDisplay
              name={"Cust. balance"}
              ammount={incomeData.balances}
              unit={"$"}
            />
          </div>
          <div className="view-info-container-item-last">
            <MoneyStorageDisplay
              name={"Total"}
              ammount={sumAllArray(
                incomeData.system,
                "price",
                incomeData.balances
              )}
              unit={"$"}
              total={true}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="view-info-container">
        <div className="view-info-container-name daily-report-container">
          <p>Daily Report</p>
          <div className="daily-report-buttons-container">
            <div
              className="daily-report-buttons-container-items"
              onClick={(e) =>
                convertToExcel(dailyData, `mubaBazarData ${filterData.type}`)
              }
            >
              <button className="daily-report-button">
                <img src={DataBaseSVG} alt="data base svg" />
              </button>
              <button className="daily-report-button">
                <img src={ArrowPointingDownSVG} alt="arrow Pointing down" />
              </button>
            </div>
            <div
              className="daily-report-buttons-container-items"
              onClick={() => setFilterDailyData(true)}
            >
              <button className="daily-report-button">
                <img src={FilterDataSVG} alt="filter data svg" />
              </button>
              <button className="daily-report-button">
                <img src={ArrowPointingDownSVG} alt="arrow Pointing down" />
              </button>
            </div>
          </div>
        </div>
        <div className="view-info-container-items-container">
          <div className="view-info-container-items">
            <MoneyStorageDisplay
              name={"Com. Total Price"}
              ammount={dailyData.completedTotalPrices}
              unit={"$"}
            />
            <MoneyStorageDisplay
              name={"Com. Shipping price"}
              ammount={dailyData.completedTotalShippingPrices}
              unit={"$"}
            />
            <MoneyStorageDisplay
              name={"Currency (TL to $)"}
              ammount={dailyData.currency}
              unit={"$"}
            />

            <MoneyStorageDisplay
              name={"Point .5"}
              ammount={dailyData.point}
              unit={"$"}
            />
            <MoneyStorageDisplay
              name={"Expend"}
              ammount={dailyData.expend}
              unit={"$"}
            />
          </div>
          <div className="view-info-container-item-last">
            <MoneyStorageDisplay
              name={"Final"}
              ammount={dailyData.finalTotal}
              unit={"$"}
              total={true}
            />
          </div>
        </div>
      </div>
      <DailyReportDataTable tableData={dailyData.completeData} />

      <PopUp trigger={filterDailyData} setTrigger={setFilterDailyData}>
        <form className="popUp-form">
          <div className="popUp-header-container">
            <p className="popUp-name">Filter Daily Report</p>
            <button
              type="button"
              className="popUp-cancel-button"
              onClick={() => setFilterDailyData(false)}
            >
              <img
                src={CancelButton}
                alt="cancelButton"
                className="popUp-cancel-button-image"
              />
            </button>
          </div>

          <div className="popUp-items-container">
            <div className="popUp-tranactions-container">
              <p className="popUp-input-name">To : </p>
              <select
                className="popUp-select"
                value={filterData.type}
                onChange={(event) => {
                  setFilterData({
                    ...filterData,
                    type: event.target.value,
                  });
                }}
              >
                <option value="" unselectable="on" hidden={true}>
                  {" "}
                </option>

                {["day", "week", "month", "year", "between"].map((item) => {
                  return (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>

            {filterData.type === "between" ? (
              <div>
                <div className="popUp-input-container">
                  <p className="popUp-input-name">From </p>
                  <input
                    type="date"
                    className="popUp-input"
                    placeholder="Initial Date"
                    value={filterData.from}
                    onChange={(event) =>
                      setFilterData({ ...filterData, from: event.target.value })
                    }
                  />
                </div>
                <div className="popUp-input-container">
                  <p className="popUp-input-name">To</p>
                  <input
                    type="date"
                    className="popUp-input"
                    placeholder="End Date"
                    value={filterData.to}
                    onChange={(event) => {
                      setFilterData({
                        ...filterData,
                        to: event.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            ) : null}
            <div className="popUp-button-container">
              <button
                className="popUp-button"
                onClick={handleSubmitDailyFilter}
              >
                Sumbit{" "}
              </button>
            </div>
          </div>
        </form>
      </PopUp>
    </div>
  );
}

export default function MoneyStorageDisplay({ name, ammount, unit, total }) {
  return (
    <div className="money-display_conatiner">
      <div className="money-display-name-container">
        <p className="money-display-name">{name}</p>
      </div>
      <div className="money-display-amount-container">
        <p
          className={`money-display-amount ${
            total ? "money--display-bold" : ""
          }`}
        >
          {unit}
          {ammount}
        </p>
      </div>
    </div>
  );
}

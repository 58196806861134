import { useState } from "react";

import EditData from "../images/EditData.svg";
import CancelButton from "../images/CancelButton.svg";
import { editCurrency } from "../services/fetchAPI";
import PopUp from "./popUp";

export default function MoneyExchangeComponent({ name, ammount, tlRate }) {
  let [showPopUp, setShowPopUp] = useState(false);
  let [currecnyData, setCurrecnyData] = useState({
    rate: "",
    currency: name.toLowerCase(),
  });

  const token = sessionStorage.getItem("token");

  function handleEditCurrency(event) {
    event.preventDefault();
    const valid = validation(currecnyData);

    valid.includes(false)
      ? console.log("Please Fill All Data")
      : editCurrency(currecnyData, token);

    setShowPopUp(false);
  }

  function validation(validateData) {
    return Object.values(validateData).map((item) => {
      if (item === "") {
        return false;
      }
      return true;
    });
  }

  return (
    <div
      className={`money-exchange-component-conatiner ${
        tlRate ? "gray-money-exchange-component" : ""
      }`}
    >
      {tlRate ? (
        <div className="money-display-name-container justify-bank-storage-items">
          <p className="money-display-name">{name}</p>
        </div>
      ) : (
        <div className="money-display-name-container justify-bank-storage-items">
          <p className="money-display-name">{name}</p>
          <img
            className="bank-storage-button"
            src={EditData}
            alt="Edit Data"
            onClick={() => {
              setShowPopUp(true);
            }}
          />
          <PopUp trigger={showPopUp} setTrigger={setShowPopUp}>
            <form className="popUp-form">
              <div className="popUp-header-container">
                <p className="popUp-name">Set {name} Value</p>
                <button
                  type="button"
                  className="popUp-cancel-button"
                  onClick={() => setShowPopUp(false)}
                >
                  <img
                    src={CancelButton}
                    alt="cancelButton"
                    className="popUp-cancel-button-image"
                  />
                </button>
              </div>
              <div className="popUp-items-container">
                <div className="popUp-input-container">
                  <p className="popUp-input-name">Amount</p>
                  <input
                    type="text"
                    className="popUp-input"
                    placeholder={ammount}
                    value={currecnyData.rate}
                    onChange={(event) => {
                      if (event.target.value >= 0) {
                        setCurrecnyData({
                          ...currecnyData,
                          rate: event.target.value,
                        });
                      }
                    }}
                  />
                </div>

                <div className="popUp-button-container">
                  <button className="popUp-button" onClick={handleEditCurrency}>
                    Sumbit
                  </button>
                </div>
              </div>
            </form>
          </PopUp>
        </div>
      )}

      <div className="money-display-amount-container">
        <p className="money-display-amount">{ammount}</p>
      </div>
    </div>
  );
}

import TrashCan from "../images/Trashcan.svg";
import { deleteTransaction } from "../services/fetchAPI";
import PopUp from "./popUp";
import { useState } from "react";

export default function TrasactionHistroyDataTable({
  transactions,
  dropDataFromTable,
}) {
  return (
    <div className="daily-report-data-table">
      <div className="daily-report-data-table-header transaction-table-items">
        <p className="data-table-header-item">Transaction From</p>
        <p className="data-table-header-item">To</p>
        <p className="data-table-header-item">Amount </p>
        <p className="data-table-header-item">User</p>
        <p className="data-table-header-item">Data</p>
        <p className="data-table-header-item">Action</p>
      </div>
      <div className="transaction-data-height">
        <div className="data-table-items transaction-data-height">
          {transactions.map((item) => {
            let newDate = new Date(item.createdAt);
            newDate =
              newDate.getFullYear() +
              "-" +
              (newDate.getMonth() + 1) +
              "-" +
              newDate.getDate();
            return (
              <Transaction
                key={item.user + item.createdAt}
                item={item}
                newDate={newDate}
                dropDataFromTable={dropDataFromTable}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

function Transaction({ item, newDate, dropDataFromTable }) {
  let [showPopUp, setShowPopUp] = useState(false);

  const token = sessionStorage.getItem("token");
  async function handleDeleteItem(id) {
    await deleteTransaction(id, token);
    dropDataFromTable(id);
    setShowPopUp(false);
  }

  return (
    <div className="daily-report-data-table-item transaction-table-items">
      <p>{(item.bankID = 1 ? "Duhok" : "Turkey")}</p>
      <p>{item.transactionTo}</p>
      <p>${item.amount.toFixed(2)}</p>
      <p>{item.user.name}</p>
      <p>{newDate}</p>
      <div>
        <button
          className="Transaction-button"
          onClick={() => {
            setShowPopUp(true);
          }}
        >
          <img src={TrashCan} alt="Trash Can" />
        </button>
        <PopUp trigger={showPopUp} setTrigger={setShowPopUp}>
          <div className="popUp-cancel-form-container">
            <div className="popUp-input-container">
              <div className="popUp-tranactions-container">
                <p className="popUp-input-name">
                  You Sure you want to Delete Item {item.id} ?
                </p>
              </div>
            </div>

            <div className="popUp-button-container">
              <button
                className="popUp-button"
                onClick={() => handleDeleteItem(item.id)}
              >
                Delete
              </button>
              <button
                className="popUp-button button-cancel"
                onClick={() => setShowPopUp(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </PopUp>
      </div>
    </div>
  );
}

import axios from "axios";

export async function fetchUser(data) {
  const user = await axios.post(
    "https://finance.mubabazar.net/api/v1/login",
    data
  );

  return user.data;
}

export async function fetchData(token) {
  const data = await axios.get("https://finance.mubabazar.net/api/v1/allData", {
    headers: { Authorization: `Bearer ${token}` },
  });
  return data.data;
}

export async function createCard(items, token) {
  const data = await axios.post(
    "https://finance.mubabazar.net/api/v1/newCard",
    items,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return data.data;
}

export async function delteCard(id, token) {
  const data = await axios.get(
    `https://finance.mubabazar.net/api/v1/deleteCard/${id}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return data.data;
}

export async function transaction(items, token) {
  const data = await axios.post(
    "https://finance.mubabazar.net/api/v1/transaction",
    items,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
}

export async function editBank(items, token) {
  const data = await axios.post(
    "https://finance.mubabazar.net/api/v1/editBank",
    items,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
}

export async function editCurrency(items, token) {
  // const items = { rate: 19.1, currency: "tl" };
  const data = await axios.post(
    "https://finance.mubabazar.net/api/v1/editCurrency",
    items,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
}

export async function deleteTransaction(id, token) {
  const data = await axios.get(
    `https://finance.mubabazar.net/api/v1/deleteTransaction/${id}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
}

export async function getIncomeData(token) {
  const data = await axios.get(
    "https://finance.mubabazar.net/api/v1/products",
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return data.data;
}

export async function getDailyReportData(reportTime, token) {
  const data = await axios.post(
    "https://finance.mubabazar.net/api/v1/dailyReport",
    reportTime,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return data.data;
}

import CancelButton from "../images/CancelButton.svg";

export default function CreditCards({
  id,
  name,
  firstAmount,
  tLira,
  date,
  rate,
  setDeleteCardPopUp,
  setSelectedCardId,
}) {
  let newDate = new Date(date);
  newDate =
    newDate.getFullYear() +
    "-" +
    (newDate.getMonth() + 1) +
    "-" +
    newDate.getDate();

  return (
    <div className="credit-card-container">
      <div className="money-display-name-container justify-bank-storage-items">
        <p className="money-display-name">{name}</p>
        {id ? (
          <img
            className="money-display-img"
            src={CancelButton}
            alt="X"
            onClick={() => {
              setSelectedCardId({ id: id, name: name });
              setDeleteCardPopUp(true);
            }}
          />
        ) : null}
      </div>
      <div className="credit-card-info-container">
        <div className="credit-card-info small-info">
          <p>first Amount:{firstAmount}</p>
          <p>{newDate}</p>
        </div>
        <div className="credit-card-info">
          <p className="credit-card-tl">
            <span>{"TL : " + Math.floor(tLira * 100) / 100}</span>
          </p>
          <p>{rate}</p>
        </div>
      </div>
    </div>
  );
}

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchUser } from "../services/fetchAPI";

import "./login.css";
import MubaLogo from "../images/mubabazarwhite.png";

export default function Login({ setUserData }) {
  let [loginUser, setLoginUser] = useState("");
  let [loginPassword, setLoginPassword] = useState("");
  let [errorMessage, setErrorMessage] = useState("");
  let [isLoadingData, setIsloadingData] = useState(false);
  let navigate = useNavigate();

  async function submitData(event) {
    event.preventDefault();

    setIsloadingData(true);

    let apiData = await fetchUser({
      email: loginUser,
      password: loginPassword,
    });

    if (apiData.token) {
      sessionStorage.setItem("isLogin", true);
      sessionStorage.setItem("token", apiData.token);
      sessionStorage.setItem("userId", apiData.user.id);
      setUserData(apiData.user);
      navigate("/");
    } else {
      setErrorMessage(apiData.status);
    }
    setIsloadingData(false);
  }

  return (
    <div className="login-page">
      <div className="login-container">
        <img className="login-logo" src={MubaLogo} alt="Muba_logo" />
        <form onSubmit={submitData} className="login-form">
          {errorMessage ? (
            <p className="login-error-message">{errorMessage}</p>
          ) : null}
          <input
            className="login-form-input"
            type="text"
            placeholder="Username"
            onChange={(event) => {
              setLoginUser(event.target.value);
            }}
            value={loginUser}
          />
          <input
            className="login-form-input"
            type="password"
            placeholder="Password"
            onChange={(event) => {
              setLoginPassword(event.target.value);
            }}
            value={loginPassword}
          />
          <button className="login-form-submit">
            {isLoadingData ? (
              <span className="loading">
                Loading<span className="loader"></span>
              </span>
            ) : (
              "Login"
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

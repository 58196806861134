import BankStorageComponent from "../components/BankStorageComponent";
import MoneyExchangeComponent from "../components/MoneyExchangeComponent";
import CreditCards from "../components/CreditCards";
import TrasactionHistroyDataTable from "../components/TransactionHistoryDataTable";
import { fetchData, createCard, delteCard } from "../services/fetchAPI";
import Loader from "../components/Loader";

import { useEffect, useState } from "react";
import PopUp from "../components/popUp";
import CancelButton from "../images/CancelButton.svg";

export default function Bank() {
  const [bankData, setbankData] = useState(null);
  let [cardsPopUp, setCardsPopUp] = useState(false);

  let [deleteCardPopUp, setDeleteCardPopUp] = useState(false);
  let [selectedCardId, setSelectedCardId] = useState({ id: "", name: "" });

  let [newCardData, setNewCardData] = useState({
    name: "",
    amount: "",
    tlRate: "",
    id: sessionStorage.getItem("userId"),
  });

  const token = sessionStorage.getItem("token");

  let banksList = bankData
    ? [bankData.bank.name, ...bankData.storages.map((item) => item.name)]
    : null;

  function dropDataFromTable(id) {
    setbankData({
      ...bankData,
      transactions: bankData.transactions.filter((item) => item.id !== id),
    });
  }

  function handleDeleteCard(cardId) {
    let deletedCardResponse = delteCard(cardId, token);
    console.log(deletedCardResponse);
    setDeleteCardPopUp(false);
    setbankData({
      ...bankData,
      cards: [...bankData.cards.filter((e) => e.id !== cardId)],
    });
  }

  function validation() {
    return Object.values(newCardData).map((item) => {
      if (item === "") {
        return false;
      }
      return true;
    });
  }

  async function handleCreateCard(event) {
    event.preventDefault();
    const valid = validation();

    if (valid.includes(false)) {
      console.log("Please Fill All Data");
    } else {
      let newCard = await createCard(newCardData, token);
      console.log(newCard);
      setbankData({
        ...bankData,
        cards: [
          ...bankData.cards,
          {
            id: parseInt(newCard.id),
            name: newCardData.name,
            firstAmount: newCardData.amount,
            TLAmount:
              parseFloat(newCardData.amount) * parseFloat(newCardData.tlRate),
            TLRate: newCardData.tlRate,
            createdAt: Date(),
          },
        ],
      });
      setCardsPopUp(false);
    }
  }

  useEffect(() => {
    fetchData(token).then((e) => {
      setbankData(e);
    });
  }, []);

  if (!bankData) {
    return <Loader />;
  }
  return (
    <div className="info-container">
      <div className="view-info-container">
        <p className="view-info-container-name">Bank</p>
        <div className="bank-info-container-items-container">
          <div className="view-info-container-items">
            <BankStorageComponent
              name={bankData.bank.name}
              ammount={bankData.bank.amount}
              unit={bankData.bank.currency}
              primary={true}
              banksList={banksList}
            />
            <BankStorageComponent
              name={bankData.storages[0].name}
              ammount={bankData.storages[0].amount}
              unit={bankData.storages[0].currency}
              banksList={banksList}
            />
            <BankStorageComponent
              name={bankData.storages[1].name}
              ammount={bankData.storages[1].amount}
              unit={bankData.storages[1].currency}
              banksList={banksList}
            />
          </div>
          <div className="view-info-container-items">
            <MoneyExchangeComponent
              name={bankData.currences[2].name.toUpperCase()}
              ammount={bankData.currences[2].price}
            />
            <MoneyExchangeComponent
              name={bankData.currences[0].name.toUpperCase()}
              ammount={bankData.currences[0].price}
            />
            <MoneyExchangeComponent
              name={"TL Rate"}
              ammount={bankData.cards[0].TLRate}
              tlRate={true}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="view-info-container">
        <div className="view-info-container-name daily-report-container">
          <p className="view-info-container-name"> Cards </p>

          <button
            className="cards-container-header-button"
            onClick={() => setCardsPopUp(true)}
          >
            Create Card +
          </button>
        </div>
        <PopUp trigger={cardsPopUp} setTrigger={setCardsPopUp}>
          <form className="popUp-form">
            <div className="popUp-header-container">
              <p className="popUp-name">Create Card</p>
              <button
                type="button"
                className="popUp-cancel-button"
                onClick={() => setCardsPopUp(false)}
              >
                <img
                  src={CancelButton}
                  alt="cancelButton"
                  className="popUp-cancel-button-image"
                />
              </button>
            </div>
            <div className="popUp-items-container">
              <div className="popUp-input-container">
                <p className="popUp-input-name">Card name</p>
                <input
                  type="text"
                  className="popUp-input"
                  placeholder="card name"
                  value={newCardData.name}
                  onChange={(event) =>
                    setNewCardData({ ...newCardData, name: event.target.value })
                  }
                />
              </div>
              <div className="popUp-input-container">
                <p className="popUp-input-name">Initial Ammount</p>
                <input
                  type="number"
                  className="popUp-input"
                  placeholder="Initial Amount"
                  value={newCardData.amount}
                  onChange={(event) => {
                    if (event.target.value >= 0) {
                      setNewCardData({
                        ...newCardData,
                        amount: event.target.value,
                      });
                    }
                  }}
                />
              </div>
              <div className="popUp-input-container">
                <p className="popUp-input-name">TL Rate</p>
                <input
                  type="number"
                  max={99}
                  className="popUp-input"
                  placeholder="TL rate"
                  value={newCardData.tlRate}
                  onChange={(event) => {
                    if (event.target.value < 100 && event.target.value >= 0) {
                      setNewCardData({
                        ...newCardData,
                        tlRate: event.target.value,
                      });
                    }
                  }}
                />
              </div>
              <div className="popUp-button-container">
                <button className="popUp-button" onClick={handleCreateCard}>
                  Sumbit{" "}
                </button>
              </div>
            </div>
          </form>
        </PopUp>
        <PopUp trigger={deleteCardPopUp} setTrigger={setDeleteCardPopUp}>
          <div className="popUp-cancel-form-container">
            <div className="popUp-input-container">
              <div className="popUp-tranactions-container">
                <p className="popUp-input-name">
                  You Sure you want to Delete Card {selectedCardId.name} ?
                </p>
              </div>
            </div>

            <div className="popUp-button-container">
              <button
                className="popUp-button"
                onClick={() => handleDeleteCard(selectedCardId.id)}
              >
                Delete
              </button>
              <button
                className="popUp-button button-cancel"
                onClick={() => setDeleteCardPopUp(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </PopUp>
        <div className="cards-items-container">
          {bankData.cards.map((item) => {
            console.log(item);
            return (
              <CreditCards
                key={item.name + item.createdAt}
                id={item.id}
                name={item.name}
                firstAmount={item.firstAmount}
                tLira={item.TLAmount}
                date={item.createdAt}
                rate={item.TLRate}
                setDeleteCardPopUp={setDeleteCardPopUp}
                setSelectedCardId={setSelectedCardId}
              />
            );
          })}
        </div>
      </div>
      <hr />
      <div className="view-info-container">
        <p className="view-info-container-name">Bank</p>
        <TrasactionHistroyDataTable
          transactions={bankData.transactions}
          dropDataFromTable={dropDataFromTable}
        />
      </div>
    </div>
  );
}

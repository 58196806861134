import CurvyRightArrow from "../images/CurvyRightArrow.svg";
import AdditionSign from "../images/AdditionSign.svg";
import SubtractionSign from "../images/SubtractionSign.svg";
import { useState } from "react";
import PopUp from "./popUp";
import { transaction, editBank } from "../services/fetchAPI";
import CancelButton from "../images/CancelButton.svg";

export default function BankStorageComponent({
  name,
  ammount,
  unit,
  primary,
  banksList,
}) {
  let [transferPopView, setTransferPopView] = useState(false);
  let [transferMoney, setTransferMoney] = useState({
    from: name,
    to: "",
    amount: "",
    userId: sessionStorage.getItem("userId"),
  });

  let [editBankPopView, setEditBankPopView] = useState(false);
  let [editPrimaryBank, setEditPrimaryBank] = useState({
    amount: "",
    operation: "",
  });

  const token = sessionStorage.getItem("token");

  function handleTransferMoney(event) {
    event.preventDefault();
    const valid = validation(transferMoney);

    valid.includes(false)
      ? console.log("Please Fill All Data")
      : transaction(transferMoney, token);

    setTransferPopView(false);
  }

  function handleEditPrimaryBank(event) {
    event.preventDefault(editPrimaryBank);

    editBank(editPrimaryBank, token);
  }
  function validation(validateData) {
    return Object.values(validateData).map((item) => {
      if (item === "") {
        return false;
      }
      return true;
    });
  }

  return (
    <div className="bank-storage-component-conatiner">
      <div className="money-display-name-container justify-bank-storage-items">
        <p className="money-display-name">{name}</p>
        <div className="bank-storage-buttons-container">
          <button
            className="bank-storage-button"
            onClick={() => setTransferPopView(true)}
          >
            <img src={CurvyRightArrow} alt="curveRightArrow" />
          </button>
          <PopUp trigger={transferPopView} setTrigger={setTransferPopView}>
            <form className="popUp-form">
              <div className="popUp-header-container">
                <p className="popUp-name">{name}</p>
                <button
                  type="button"
                  className="popUp-cancel-button"
                  onClick={() => setTransferPopView(false)}
                >
                  <img
                    src={CancelButton}
                    alt="cancelButton"
                    className="popUp-cancel-button-image"
                  />
                </button>
              </div>
              <div className="popUp-items-container">
                <div className="popUp-input-container">
                  <p className="popUp-input-name">
                    From : <span>{name}</span>
                  </p>
                  <div className="popUp-tranactions-container">
                    <p className="popUp-input-name">To : </p>
                    <select
                      className="popUp-select"
                      value={transferMoney.to}
                      onChange={(event) => {
                        setTransferMoney({
                          ...transferMoney,
                          to: event.target.value,
                        });
                      }}
                    >
                      <option value="" unselectable="on" hidden={true}>
                        {" "}
                      </option>

                      {primary ? (
                        banksList.map((item) => {
                          if (item !== name)
                            return (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            );
                        })
                      ) : (
                        <option value={banksList[0]}> {banksList[0]}</option>
                      )}
                    </select>
                  </div>
                  <p className="popUp-input-name">Amount</p>
                  <input
                    type="number"
                    className="popUp-input"
                    placeholder={ammount}
                    value={transferMoney.amount}
                    onChange={(event) => {
                      if (event.target.value >= 0) {
                        setTransferMoney({
                          ...transferMoney,
                          amount: event.target.value,
                        });
                      }
                    }}
                  />
                </div>

                <div className="popUp-button-container">
                  <button
                    className="popUp-button"
                    onClick={handleTransferMoney}
                  >
                    Sumbit
                  </button>
                </div>
              </div>
            </form>
          </PopUp>
        </div>
      </div>
      <div className="bank-storage-component-amount-container">
        <p className="bank-storage-component-amount">
          {ammount} {unit}
        </p>
        {primary ? (
          <div className="bank-storage-buttons-container">
            <button
              className="bank-storage-button"
              onClick={() => {
                setEditBankPopView(true);
                setEditPrimaryBank({ ...editPrimaryBank, operation: "-" });
              }}
            >
              <img src={SubtractionSign} alt="SubtractionSign" />
            </button>
            <button
              className="bank-storage-button"
              onClick={() => {
                setEditBankPopView(true);
                setEditPrimaryBank({ ...editPrimaryBank, operation: "+" });
              }}
            >
              <img src={AdditionSign} alt="AdditionSign" />
            </button>
            <PopUp trigger={editBankPopView} setTrigger={setEditBankPopView}>
              <form className="popUp-form">
                <div className="popUp-header-container">
                  <p className="popUp-name">
                    {editPrimaryBank.operation === "+"
                      ? "Add to"
                      : "Subtract from"}{" "}
                    {name}
                  </p>
                  <button
                    type="button"
                    className="popUp-cancel-button"
                    onClick={() => setEditBankPopView(false)}
                  >
                    <img
                      src={CancelButton}
                      alt="cancelButton"
                      className="popUp-cancel-button-image"
                    />
                  </button>
                </div>
                <div className="popUp-items-container">
                  <div className="popUp-input-container">
                    <p className="popUp-input-name">Amount</p>
                    <div className="popUp-double-Item-input">
                      <div className="popUp-input-operation-sign">
                        {editPrimaryBank.operation}
                      </div>
                      <input
                        type="number"
                        className="popUp-input double-item-input"
                        placeholder={ammount}
                        value={editPrimaryBank.amount}
                        onChange={(event) => {
                          if (event.target.value >= 0) {
                            setEditPrimaryBank({
                              ...editPrimaryBank,
                              amount: event.target.value,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="popUp-button-container">
                    <button
                      className="popUp-button"
                      onClick={handleEditPrimaryBank}
                    >
                      Sumbit
                    </button>
                  </div>
                </div>
              </form>
            </PopUp>
          </div>
        ) : null}
      </div>
    </div>
  );
}

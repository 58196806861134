import { Link } from "react-router-dom";

export default function SideNavPanel() {
  return (
    <div className="side-nav-panal">
      <div className="side-nav-panal-logo-container">
        <img
          className="side-nav-panal-logo"
          src="https://www.mubabazar.net/image/logo.ico"
          alt="muba_logo"
        />
        <p className="side-nav-logo-name">Mubabazar</p>
      </div>
      <div className="side-nav-panal-list">
        <Link className="side-nav-panal-list-item" to={"/income"}>
          <span className="side-nav-panal-list-item-name">Income</span>
        </Link>
        <Link className="side-nav-panal-list-item" to={"/bank"}>
          <span className="side-nav-panal-list-item-name">bank</span>
        </Link>
      </div>
    </div>
  );
}
